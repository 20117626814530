<template>
  <div class="campaignInsights">
    <div class="campaignInsights__summary">
      <h3>{{$t('Campaign summary')}}</h3>
      <p>{{$t('Influencer accepted')}}: {{campaignsubscribers.length}}</p>
    </div>
    <a href="javascript:void(0)" class="btn btn-primary" @click="changeDisplayCards">{{$t('View all stories')}}</a>
    <div v-if="campaignsubscribers.length > 0 && displayCards" class="image-gallery">
      <campaign-promoters-card
        v-for="(subscriber, i) in campaignsubscribers"
        :key="i + 1"
        :subscriber="subscriber"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      displayCards: false,
    }
  },
  props: {
    campaignsubscribers: [Object, Array, Number, String],
  },
  components: {
    CampaignPromotersCard: () =>
      import(
        /* webpackChunkName: "campaignPromotersCard" */ "./partials/CampaignPromotersCard.vue"
      ),
  },
  methods: {
    changeDisplayCards() {
      if (this.displayCards == true) {
        this.displayCards = false;
      } else {
        this.displayCards = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.campaignInsights {
  &__summary {
    @media screen and (max-width: 767px) {
      padding: rem(24px) rem(16px);
      border-radius: 16px;
      background: #fff;
      margin-bottom: rem(16px);
      p {
        margin: 0;
      }
    }
    p {
      font-size: rem(18px);
      color: var(--textPrimary);
      font-weight: 500;
    }
  }
  .image-gallery {
    margin-top: 10px;
  }
}
</style>
